import React from 'react'
import ReactDOM from 'react-dom'
import {useState, useEffect, useRef} from 'react'
import {request, gql} from 'graphql-request'

const POST_FEEDBACK = gql`
  mutation PostFeedback($message: String!, $url: String!) {
    insert_feedback(objects: [{message: $message, url: $url}]) {
      affected_rows
    }
  }
`
const NEXT_PUBLIC_FEEDBACK_GRAPHQL_URL = 'https://pmt-feedback.hasura.app/v1/graphql'

const postFeedback = (vars) => request(NEXT_PUBLIC_FEEDBACK_GRAPHQL_URL, POST_FEEDBACK, vars)

const Footer = ({feedbackVisible, className}) => {
  const [message, setMessagge] = useState('')
  const [messageSent, setMessageSent] = useState(false)
  const [showForm, setShowForm] = useState(feedbackVisible)
  const textArea = useRef()

  const toggleForm = () => setShowForm(!showForm)

  const handleChange = (e) => setMessagge(e.target.value)

  const sendMessage = async () => {
    if (message.trim() === '') return

    postFeedback({message: message, url: document.location.href})
    setMessagge('')
    setMessageSent(true)
    toggleForm()
  }

  useEffect(() => {
    if (showForm) {
      textArea.current.focus()
      textArea.current.scrollIntoView()
    }
  }, [showForm])

  useEffect(() => {
    setShowForm(feedbackVisible)
  }, [feedbackVisible])

  return (
    <div className='feedback-form'>
      {showForm ||
        (messageSent ? (
          <span>Mulțumim pentru mesaj!</span>
        ) : (
          <div className='feedback-btn-container'>
            <a
              href='#'
              onClick={(e) => {
                toggleForm()
                e.preventDefault()
              }}
              className='feedback-btn'
            >
              Feedback
            </a>
          </div>
        ))}

      {showForm && (
        <div key='form'>
          <label htmlFor='problema'>Ai observat o problemă sau ai vreo sugestie?</label>
          <textarea id='problema' ref={textArea} rows={3} onChange={handleChange} value={message}></textarea>
          <p>
            Pentru alte probleme ce țin de Primărie ne poți contacta la{' '}
            <a href='mailto:primariatm@primariatm.ro'>primariatm@primariatm.ro</a> sau{' '}
            <a href='tel:0256 408 300'>0256 408 300</a>.
          </p>
          <div className='feedback-controls'>
            <button type='button' onClick={sendMessage}>
              Trimite
            </button>{' '}
            sau{' '}
            <a
              href='#'
              onClick={(e) => {
                toggleForm()
                e.preventDefault()
              }}
            >
              Renunță
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default Footer

ReactDOM.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
)
